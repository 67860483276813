import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import React from "react"
import useStations from "../utils/hooks/useStations"

const DefaultBiofixDatesPage = () => {
  const { station } = useStations()
  return (
    <Layout station={station}>
      <Seo title="Default Biofix Dates" />

      <div className="container px-3 mx-auto container-article xl:pt-6 lg:px-4 xl:px-12">
        <div className="grid grid-cols-1 gap-6 mb-8 lg:mb-12 lg:grid-flow-col xl:gap-12 md:grid-cols-12">
          <div className="md:col-span-8">
            <h1>Default Biofix Dates</h1>

            <p>
              Many pest forecast and crop management tools require a biofix date
              to start calculating the pest risk and crop results. NEWA tools
              utilize default biofix dates, estimated as described below, for
              users who are not tracking the specific biofix.{" "}
              <strong>
                For improved accuracy, always use your recorded biofix date for
                the crop or pest management tool you are interested in. 
              </strong>
            </p>
            <p>
              <Link to="https://newa-public-assets.s3.amazonaws.com/apple-biofix-record-sheets-final.pdf">Click here to download a useful PDF record sheet</Link> for apple phenology and insect first trap catch dates.
            </p>

            <table>
              <thead>
                <tr>
                  <th>Crop or IPM Tool</th>
                  <th>Biofixes</th>
                  <th>Default Biofix Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Apple Scab</td>
                  <td>50% McIntosh green tip</td>
                  <td>
                    Estimate based on DD accumulations correlated with
                    historical observations.
                  </td>
                </tr>
                <tr>
                  <td>Fire Blight</td>
                  <td>First blossom open</td>
                  <td>
                    Estimate based on DD accumulations correlated with
                    historical observations.
                  </td>
                </tr>
                <tr>
                  <td>Sooty Blotch and Flyspeck</td>
                  <td>90% petal fall</td>
                  <td>
                    Estimate based on DD accumulations correlated with
                    historical observations.
                  </td>
                </tr>
                <tr>
                  <td>Apple Maggot</td>
                  <td>First trap catch</td>
                  <td>
                    Estimate based on DD accumulations correlated with
                    historical observations.
                  </td>
                </tr>
                <tr>
                  <td>Codling Moth</td>
                  <td>
                    First sustained trap catch; Second generation flight start
                  </td>
                  <td>
                    Estimate based on DD accumulations correlated with
                    historical observations.
                  </td>
                </tr>
                <tr>
                  <td>Oriental Fruit Moth</td>
                  <td>
                    First sustained trap catch; Second generation flight start;
                    Third generation flight start
                  </td>
                  <td>
                    Estimate based on DD accumulations correlated with
                    historical observations.
                  </td>
                </tr>
                <tr>
                  <td>Obliquebanded Leafroller</td>
                  <td>First sustained trap catch</td>
                  <td>
                    Estimate based on DD accumulations correlated with
                    historical observations.
                  </td>
                </tr>
                <tr>
                  <td>Plum Curculio</td>
                  <td>90% petal fall</td>
                  <td>
                    Estimate based on DD accumulations correlated with
                    historical observations.
                  </td>
                </tr>
                <tr>
                  <td>San Jose Scale</td>
                  <td>First trap catch; Second generation emergence</td>
                  <td>
                    Estimate based on DD accumulations correlated with
                    historical observations.
                  </td>
                </tr>
                <tr>
                  <td>Spotted Tentiform Leafminer</td>
                  <td>First trap catch; Second generation flight start</td>
                  <td>
                    Estimate based on DD accumulations correlated with
                    historical observations.
                  </td>
                </tr>
                <tr>
                  <td>Apple Carbohydrate Thinning</td>
                  <td>Green tip; Bloom</td>
                  <td>
                    Estimate based on DD accumulations correlated with
                    historical observations.
                  </td>
                </tr>
                <tr>
                  <td>Apple Irrigation</td>
                  <td>Green tip</td>
                  <td>
                    Estimate based on DD accumulations correlated with
                    historical observations.
                  </td>
                </tr>
                <tr>
                  <td>Grape Berry Moth</td>
                  <td>Wild grape bloom</td>
                  <td>
                    Estimate based on DD accumulations correlated with
                    historical observations.
                  </td>
                </tr>
                <tr>
                  <td>Tomato Diseases</td>
                  <td>Transplant date</td>
                  <td>May 15</td>
                </tr>
                <tr>
                  <td>Tomato Diseases</td>
                  <td>Date of potato cull sprouting or volunteer emergence</td>
                  <td>May 1</td>
                </tr>
                <tr>
                  <td>Onion Diseases</td>
                  <td>Planting date</td>
                  <td>April 21</td>
                </tr>
                <tr>
                  <td>Potato Diseases</td>
                  <td>Emergence date</td>
                  <td>May 10</td>
                </tr>
                <tr>
                  <td>Potato Diseases</td>
                  <td>Date of potato cull sprouting or volunteer emergence</td>
                  <td>May 1</td>
                </tr>
              </tbody>
            </table>

            <footer className="pt-6 mt-12 text-base border-t border-gray-200">
              <p>
                Page updated June 2020 by D. Olmstead, K. Eggleston and J.
                Carroll
              </p>
            </footer>
          </div>
          <div className="pt-4 text-base text-gray-700 border-t-2 border-gray-200 md:col-span-4 md:border-l-2 md:border-t-0 md:pt-3 md:pl-4 xl:pl-8">
          <h2>Get a Weather Station</h2>
            <p>
              NEWA is compatible with special configurations of {' '}
              <Link to="https://www.onsetcomp.com/corporate/partners/newa">
                Onset 
              </Link>{' '}
              and <Link to="https://kestrelmet.com/kestrelmet-6000-ag-weather-station-newa">KestrelMet</Link> weather instruments. See what your options are.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Buy a Weather Stations"
                to="/buy-a-weather-station"
              >
                Buy a Weather Station
              </Link>
            </p>

            <hr></hr>

            <h2>Have a question?</h2>
            <p>
              Reach out to our support center and ask questions to get support for NEWA tools, resources, and weather
              stations.
            </p>
            <p>
              <a
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                href="mailto:support@newa.zendesk.com"
              >
                Contact the NEWA Help Desk
              </a>
            </p>

            <hr></hr>

            <h2>Become a Partner</h2>
            <p>
              NEWA is a partnership of land grant universities and grower
              associations. If you live in a partner state, you can{" "}
              <Link to="/buy-a-weather-station">buy a weather station</Link> for
              your farm and connect to NEWA.
            </p>
            <p>
              <Link
                className="mt-3 rounded-none btn btn-secondary-reverse btn-large"
                aria-label="Learn More About Becoming a Partner"
                to="/become-partner"
              >
                Learn More
              </Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default DefaultBiofixDatesPage
